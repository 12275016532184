<template>
  <layout-main>
    <div class="home">
      <!-- Header -->
      <div class="subheader">
        <div class="subheader-main">
          <div class="subheader-left">
            <h3>
              {{ $t('labels.signatures') }}
            </h3>
            <span class="separator-line"></span>
            <div class="breadcrumbs">
              <router-link to="/email" class="breadcrumbs-link"> {{ $t('labels.home') }}</router-link>
              <span class="separator-dot"></span>
              <router-link to="/email" class="breadcrumbs-link"> {{ $t('labels.email') }}</router-link>
              <span class="separator-dot"></span>
              <router-link to="/email/signatures" class="breadcrumbs-link"> {{ $t('labels.signatures') }}</router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- Header -->

      <div class="row">
        <div class="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
          <div class="widget progress-list">
            <div class="widget-header">
              <div class="widget-header-label">
                <h3>
                  {{ $t('labels.available-email-signatures') }} <small>{{ lists.length }} {{ $t('labels.available').toLowerCase() }}</small>
                </h3>
              </div>
            </div>
            <div class="widget-body no-padding">
              <vuetable
                  class=""
                  ref="vuetable"
                  :api-mode="false"
                  :fields="fields"
                  :per-page="perPage"
                  no-data-template="Ingen data tillgänglig"
                  :data-manager="dataManager"
                  pagination-path="pagination"
                  @vuetable:pagination-data="onPaginationData"
              >
              </vuetable>
              <div style="padding-top: 10px;">
                <vuetable-pagination ref="pagination"
                                     @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout-main>
</template>

<script>
import Vuetable from 'vuetable-2/src/components/Vuetable';
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination';
import LayoutMain from '@/views/Layouts/Main';
import BojAPI from '@/api/boj';
import _ from 'lodash';

export default {
  name: 'home',
  components: {
    LayoutMain,
    Vuetable,
    VuetablePagination,
  },
  data() {
    return {
      lists: {},
      isLoading: false,
      fields: [
        {
          width: '12%',
          name: 'name',
          title: this.$t('labels.name'),
          sortField: 'name',
        },
        {
          width: '12%',
          name: 'email',
          title: this.$t('labels.email'),
          sortField: 'email',
        },
        {
          width: '12%',
          name: 'reply_to',
          title: this.$t('labels.reply-to'),
          sortField: 'reply_to',
        },
      ],
      perPage: 20,
    };
  },

  watch: {
    lists(newVal, oldVal) {
      this.$refs.vuetable.refresh();
    },
  },
  beforeRouteEnter(to, from, next) {
    BojAPI.getEmailSignatures()
        .then((response) => {
          next((vm) => vm.setData(response.data));
        })
        .catch((error) => {
        });
  },
  beforeRouteUpdate(to, from, next) {
    BojAPI.getEmailSignatures().then((response) => {
      this.setData(response.data);
      next();
    });
  },

  methods: {
    setData(payload) {
      this.lists = payload;
      this.isLoading = false;
    },

    onRowClicked(data, field, event) {
      this.$router.push({name: 'ticket', params: {id: data.id}});
    },

    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    dataManager(sortOrder, pagination) {
      let local = this.lists;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        local = _.orderBy(local, sortOrder[0].sortField, sortOrder[0].direction);
      }

      pagination = this.$refs.vuetable.makePagination(local.length, this.perPage);

      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to),
      };
    },
    onActionClicked(action, data) {
      console.log('slot actions: on-click', data.name);
    },
  },
};
</script>
